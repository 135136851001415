import ContractRenderer from "src/components/contract-renderer";
import generateSections from "src/cryptoUtilities/contract-sections";
import { useMetaMask } from "src/store/metamask";

const OP = [["private_stake", "private_stake_timeRemaining"]];

const StakeSection = () => {
  const [data] = useMetaMask();

  const sections = generateSections(data, OP);

  return <ContractRenderer sections={sections} />;
};

export default StakeSection;
