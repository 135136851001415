import ContractRenderer from "src/components/contract-renderer";
import generateSections from "src/cryptoUtilities/contract-sections";
import { useMetaMask } from "src/store/metamask";

const OP = [
  ["stakingContract_selfMaturing", "timeRemaining_selfMaturing"],
  ["stakingContract_new", "timeRemaining_new"],
  ["stakingContract_35", "timeRemaining_35"],
  ["stakingContract", "timeRemaining"],
];

const StakeSection = () => {
  const [data] = useMetaMask();

  const sections = generateSections(data, OP);

  return <ContractRenderer sections={sections} />;
};

export default StakeSection;
