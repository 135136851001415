import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Ternary from "src/components/ternary";
import {
    PRIVATE_LOGIN_FRONTEND_ORIGIN,
    PRIVATE_LOGIN_ORIGIN,
    PRIVATE_PRODUCT_IDS,
} from "src/config";
import { setSession } from "src/utils/session";
import { object, string } from "yup";

const defaultValues = {
    email: "",
    password: "",
    product_id: JSON.parse(PRIVATE_PRODUCT_IDS),
};

const schema = object().shape({
    email: string().required("Email or Username is required"),
    password: string().required("Password is required"),
});
const StyledTextField = styled(TextField)`
    border-radius: 1rem;
    & .MuiInputLabel-root {
        color: #fff;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border-radius: 1rem;
        }
    }

    & .MuiInputBase-input {
        color: #fff;
    }
`;

const StyledButton = styled(Button)`
    background: linear-gradient(
        135deg,
        #05ff00,
        #00ff6dad 51.65%,
        #00858f 99.83%
    ) !important;
    box-shadow: 0 0 1px #00ffaa36, 0 2px 4px -1px rgb(10 82 29 / 12%),
        0 16px 24px #00ff2e26, 0 8px 8px -4px rgb(69 245 80 / 12%) !important;
    color: #fff;
    text-transform: capitalize;
    font-weight: 800;
`;

const LoginButton = styled(LoadingButton)`
    background: linear-gradient(
        135deg,
        #05ff00,
        #00ff6dad 51.65%,
        #00858f 99.83%
    ) !important;
    border-radius: 72px !important;
    box-shadow: 0 0 1px #00ffaa36, 0 2px 4px -1px rgb(10 82 29 / 12%),
        0 16px 24px #00ff2e26, 0 8px 8px -4px rgb(69 245 80 / 12%) !important;
    color: #fff;
    text-transform: capitalize;
    border-radius: 1rem;
    font-weight: 800;
    padding: 1rem;
`;

const Login = () => {
    const methods = useForm({ defaultValues, resolver: yupResolver(schema) });

    const {
        handleSubmit,
        register,
        formState: { isSubmitting, errors },
        setError,
    } = methods;
    const [notPurchased, setNotPurchased] = useState(false);

    const navigate = useNavigate();
    const onSubmit = async ({ product_id, ...inputData }) => {
        const reqData = new FormData();
        Object.entries(inputData).forEach(([k, v]) => reqData.append(k, v));
        product_id.forEach((id) => reqData.append("product_id[]", id));
        try {
            const res = await fetch(`${PRIVATE_LOGIN_ORIGIN}/api/stake-login`, {
                method: "POST",
                body: reqData,
            });
            const { access_token, product_active, message, ...rest } =
                await res.json();
            if (res.status === 401) {
                setError("after_submit", { message });
                return;
            }

            const isProductPurchased = product_active === 1;

            if (isProductPurchased) {
                setSession(access_token);
                navigate("/private-stake");
            } else {
                setNotPurchased(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <Box className="main">
                <section
                    style={{
                        marginTop: "100px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            backgroundColor: "#1e2025",
                            width: "500px",
                            p: 3,
                            paddingBottom: 5,
                            paddingTop: 5,
                        }}
                    >
                        <Stack
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                color="#fff"
                                fontWeight="bold"
                                variant="h6"
                            >
                                Login To Continue
                            </Typography>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    "& .MuiFormControl-root": {
                                        border: "solid 1px #cccccc2b",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#ffffff00",
                                        },
                                    },
                                }}
                            >
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Ternary
                                            when={Boolean(errors?.after_submit)}
                                            then={
                                                <Alert
                                                    variant="filled"
                                                    severity="error"
                                                    sx={{ mb: 2 }}
                                                >
                                                    {
                                                        errors.after_submit
                                                            ?.message
                                                    }
                                                </Alert>
                                            }
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item md={12}>
                                                <StyledTextField
                                                    {...register("email")}
                                                    fullWidth
                                                    label="Email"
                                                    error={Boolean(
                                                        errors.email
                                                    )}
                                                    helperText={
                                                        errors.email?.message
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <StyledTextField
                                                    {...register("password")}
                                                    fullWidth
                                                    label="Password"
                                                    error={Boolean(
                                                        errors.password
                                                    )}
                                                    helperText={
                                                        errors.password?.message
                                                    }
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() =>
                                                                        setShowPassword(
                                                                            !showPassword
                                                                        )
                                                                    }
                                                                >
                                                                    <Iconify
                                                                        sx={{
                                                                            color: "#fff",
                                                                        }}
                                                                        icon={
                                                                            showPassword
                                                                                ? "eva:eye-fill"
                                                                                : "eva:eye-off-fill"
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item md={12}>
                                                <LoginButton
                                                    loading={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    fullWidth
                                                >
                                                    login
                                                </LoginButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </FormProvider>
                            </Box>
                        </Stack>
                    </Card>
                </section>
            </Box>

            <Dialog
                open={notPurchased}
                onClose={() => setNotPurchased(false)}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: "#212429",
                        borderRadius: "1rem",
                    },
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    sx={{ fontWeight: 600, color: "#fff" }}
                >
                    This is an IPO Members only area:
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ fontWeight: 600, color: "#fff" }}>
                        <Typography variant="body1">
                            Thank you for your interest in this private IPO
                            members' area. To access this area, and take
                            advantage of all of the features and benefits on
                            offer, you must be an IPO Member. For more
                            information and to purchase an IPO Membership,
                            please visit{" "}
                            <a href="https://bitcointaf.com/product/BitcoinTAF-IPO/">
                                www.BitcoinTAF.com
                            </a>
                            .
                        </Typography>
                    </DialogContentText>
                    <DialogActions>
                        <Stack direction="row" spacing={1}>
                            <Button
                                color="error"
                                variant="outlined"
                                onClick={() => setNotPurchased(false)}
                            >
                                cancel
                            </Button>
                            <StyledButton
                                LinkComponent="a"
                                target="_blank"
                                href={PRIVATE_LOGIN_FRONTEND_ORIGIN}
                                variant="contained"
                            >
                                purchase
                            </StyledButton>
                        </Stack>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Login;
